<template>
  <vx-card style="cursor: pointer" @click="openWidget(widget.widgetId, widget.url)">
    <div slot="no-body">
      <img :src="widget.thumbnail" 
      @error="setAltImgForThumbnail"
      class="responsive card-img-top">
      <!-- onerror="this.src='https://dummyimage.com/32x32/0E4B79/FFFFFF&amp;text=B'" -->
    </div>
    <div class="flex flex-wrap justify-start">
      <img style="height:32px" 
        :src="widget.icon"
        @error="setAltImgForIcon"
      >
      <h5 class="ml-2 mt-2">{{widget.name}}</h5>
    </div>
    <p v-if="showDescription" class="text-grey mt-4">{{strippedDescription}}</p>
    <p v-if="showCounter" class="mt-4">Widgets: {{widget.count}}</p>
  </vx-card>
</template>

<script>

export default {
  props: {
    applicationId: { required: true },
    widget: { required: true },
    showDescription: {default: true },
    showCounter: {default: false }
  },
  data () {
    return {
    }
  },
  computed: {
    strippedDescription () {
      let regex = /(<([^>]+)>)/ig;
      return this.widget.description && 
        this.widget.description.replace(regex, "")
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    openWidget (widgetId, widgetUrl) {
      this.$router.push({ name: 'application-widget-instances', 
        params: { 
          widgetUrl: widgetUrl,
          applicationId: this.applicationId
        }
      })
    },
    setAltImgForThumbnail (event) {
      const altText = encodeURIComponent(this.widget.name)
      event.target.src = `https://dummyimage.com/1280X720/F6C263/EE985A.png&text=${altText}`
    },
    setAltImgForIcon (event) {
      const altText = this.widget.name && this.widget.name.charAt(0)
      event.target.src = `https://dummyimage.com/32x32/0E4B79/57B5FA.png&text=${altText}`
    }
  }
}
</script>
